<template>
  <div>
    <div class="app-top">
      <!-- <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list> -->
    </div>
    <div class="center">
      <div class="container">
        <div style="max-width: 400px; margin:10px auto;padding: 40px 0 20px 0;">
          <el-form ref="form" :model="form" :rules="rules" :label-position=" isPc ? 'right' :'right'" label-width="100px" size="normal">
            <el-form-item>
              <!-- 标题 -->
              <div style="margin-bottom: 10px;text-align: center;">
                <h2 style="font-size: 22px;font-weight: bold;color:#333">账号注册</h2>
                <h3
                  style="margin-top: 10px;font-size: 16px;font-weight: normal;color:#666"
                  v-if="$route.query.softLicenseCode"
                >{{form.productName}}</h3>
              </div>
            </el-form-item>
            <div v-if="isSuccess==false">
              <el-form-item prop="productCode" label="选择产品" v-if="!$route.query.softLicenseCode">
                <product-select
                  :text="form.productName"
                  :onlyHasNetwork="true"
                  @change="form.productCode = $event.licenseCode,form.productName = $event.name"
                />
              </el-form-item>
              <el-form-item prop="phone" label="手机号码">
                <el-input v-model="form.phone" placeholder="登录账号" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item prop="code" label="验证码">
                <el-input v-model="form.code" style="width:50%" placeholder="短信验证码" type="text" />
                <el-button
                  style="width: calc(50% - 10px);margin-left: 10px;"
                  type
                  :disabled="!isDefault"
                  :class="['code-side',!isDefault ? 'code-side-default' : '']"
                  @click="getCode"
                >{{ codeName }}</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSubmit" style="width: 100%;">免费注册</el-button>
                <div style="text-align: center;margin-top: 10px;">
                  <contact-service text="联系客服"></contact-service>
                </div>
              </el-form-item>
            </div>
            <el-form-item v-if="isSuccess">
              <div>
                <div>
                  <div style="color:green;">注册成功！</div>
                  <div style="line-height: 26px;">
                    账号：{{ form.phone }}
                    <br />用户：admin
                    <br />密码：空
                    <div style="color:#999">默认密码为空，登录后可修改密码。</div>
                    <div style="color:#999">请返回客户端登录即可。</div>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <comm-desc />
  </div>
</template>
  
<script>
import API from '@/api/_common.js'
import commDesc from '@/components/commDesc'
import productSelect from '@/components/productSelect.vue'
export default {
  name: 'TenantRegister',
  components: {
    commDesc,
    productSelect
  },
  data() {
    return {
      dataList: [],
      form: {
        productCode: '',
        productName: '',
        phone: '',
        code: '',
        name: ''
      },
      codeName: '获取验证码',
      isDefault: true,
      isSuccess: false,
      rules: {
        productCode: [{ required: true, message: '请选择产品', trigger: 'change' }],
        phone: [{ required: true, message: '请输入手机号', trigger: 'change' }],
        code: [{ required: false, message: '请输入验证码', trigger: 'change' }]
      }
    }
  },
  watch: {},
  computed: {
    isPc() {
      return document.body.clientWidth > 800
    }
  },
  mounted() {
    if (this.$route.query) {
      this.form.productCode = this.$route.query.softLicenseCode || ''
      this.form.productName = this.$route.query.softName || ''
    }
  },
  created() {},
  methods: {
    countDown(num, that) {
      var remainTime = num
      var cacheTime = new Date().getTime()
      var timer = setInterval(function () {
        var currTime = new Date().getTime()
        var vanishTimie = Math.ceil(currTime - cacheTime) // 获得消失的时间
        var newRemainTime = parseInt(remainTime - vanishTimie / 1000)
        if (newRemainTime >= 1) {
          that.codeName = `重新获取(${newRemainTime}s)`
          that.isDefault = false
        } else {
          that.codeName = '获取验证码'
          that.isDefault = true
          clearInterval(timer)
        }
      }, 1000)
    },
    getCode() {
      this.$refs.form.clearValidate(['code'])
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.isDefault) {
            this.countDown(60, that)
            API.verifyCodeSend({ product: this.form.productCode, target: that.form.phone, type: 'Register' }).then(res => {
              this.isDefault = false
              this.$message({
                message: res.message || '操作成功',
                type: 'success',
                duration: '2000'
              })
            })
          }
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validateField(['code'])
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          API.register({
            product: this.form.productCode,
            account: that.form.phone,
            code: that.form.code,
            // invite: this.common.inviteGet(),
            from: 'Web'
          }).then(res => {
            this.isSuccess = true
            let username = res.data.username
            let passwordName = res.data.password || '(空)'
            // let password = res.data.password
            alert(`默认用户：${username}\r\n，默认密码：${passwordName}\r\n。登录后请及时修改。`)
          })
        }
      })
    }
  }
}
</script>
  <style lang="scss" scoped>
@media screen and (max-width: 576px) {
}

.app-top {
  margin-top: 58px;
}
</style>